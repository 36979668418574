import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { SettingRepository } from '../repositories/setting.repo';
import { SettingService } from '../services/setting.service';
import { UploadLogoRequestPayload } from '../setting_types';

const NAMESPACE = 'UPLOAD_LOGO';

interface InitialState {
  status: HttpStatus;
  message?: string;
}

const initialState: InitialState = {
  status: HttpStatus.IDLE,
  message: undefined,
};

export const uploadLogo = createAsyncThunk(`${NAMESPACE}/post`, async (payload: UploadLogoRequestPayload) => {
  const settingRepo = new SettingRepository();
  const settingService = new SettingService(settingRepo);
  const response = await settingService.uploadLogo(payload);
  return response;
});

const uploadLogoSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetUploadLogoStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadLogo.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(uploadLogo.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(uploadLogo.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetUploadLogoStatus } = uploadLogoSlice.actions;

export default uploadLogoSlice.reducer;
