import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { UploadLogoRequestPayload } from '../../settings/setting_types';
import { SignUpRepository } from '../repositories/sign_up.repo';
import { SignUpService } from '../services/sign_up.service';

const NAMESPACE = 'UPLOAD_PROOF_OF_ADDRESS';

interface InitialState {
  status: HttpStatus;
  message?: string;
}

const initialState: InitialState = {
  status: HttpStatus.IDLE,
  message: undefined,
};

export const uploadProofOfAddress = createAsyncThunk(`${NAMESPACE}/post`, async (payload: UploadLogoRequestPayload) => {
  const signupRepo = new SignUpRepository();
  const signupService = new SignUpService(signupRepo);
  const response = await signupService.uploadProofOfAddress(payload);
  return response;
});

const uploadProofOfAddressSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetUploadProofOfAddressStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadProofOfAddress.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(uploadProofOfAddress.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(uploadProofOfAddress.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetUploadProofOfAddressStatus } = uploadProofOfAddressSlice.actions;

export default uploadProofOfAddressSlice.reducer;
