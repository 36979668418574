import { SignUpFormData } from '../../../schema/auth.schema';
import { UploadLogoRequestPayload, UploadLogoResponsePayload } from '../../settings/setting_types';
import { SignUpRepository } from '../repositories/sign_up.repo';
import { OnboardVendorResponsePayload, VerifyOTPResponsePayload } from '../types';

export class SignUpService {
  constructor(private readonly signupRepo: SignUpRepository) {}

  async onboardVendor(): Promise<OnboardVendorResponsePayload> {
    const onboardVendorResponse = await this.signupRepo.onboardVendor();
    return onboardVendorResponse;
  }

  async signup(payload: SignUpFormData): Promise<OnboardVendorResponsePayload> {
    const response = await this.signupRepo.signup(payload);
    return response;
  }

  async verifySignupOtp(otp: string): Promise<VerifyOTPResponsePayload> {
    const verifySignupOtpResponse = await this.signupRepo.verifySignupOtp(otp);
    return verifySignupOtpResponse;
  }

  async uploadProofOfAddress(payload: UploadLogoRequestPayload): Promise<UploadLogoResponsePayload> {
    const response = await this.signupRepo.uploadProofOfAddress(payload);
    return response;
  }
}
