import { PaginationPayload } from '../../../commons/types/pagination.type';
import {
  AddInventoryDiscountRequestPayload,
  AddInventoryDiscountResponsePayload,
  CreateProductPayload,
  CreateProductResponsePayload,
  DeleteProductResponsePayload,
  GetProductDetailsPayload,
  GetProductDetailsResponsePayloadObject,
  GetProductsResponsePayload,
  GetVendorCommissionResponsePayload,
  GetVendorInventoriesRequestParams,
  GetVendorInventoriesResponsePayload,
  GetVendorInventoryRequestPayload,
  GetVendorInventoryResponsePayload,
  ProductOutOfStockRequestPayload,
  ProductOutOfStockResponsePayload,
  TotalNumberOfProductsResponsePayload,
  UpdateProductPayload,
  UpdateProductResponsePayload,
  UpdateProductStatusPayload,
} from '../product_types';
import { ProductsRepository } from '../repositories/products.repo';

export class ProductsService {
  constructor(private readonly productsRepo: ProductsRepository) {}

  async createProduct(payload: CreateProductPayload): Promise<CreateProductResponsePayload> {
    const createProductResponse = await this.productsRepo.createProduct(payload);
    return createProductResponse;
  }

  async deleteProduct(payload: GetProductDetailsPayload): Promise<DeleteProductResponsePayload> {
    const deleteProductResponse = await this.productsRepo.deleteProduct(payload);
    return deleteProductResponse;
  }

  async getProductDetails(payload: GetProductDetailsPayload): Promise<GetProductDetailsResponsePayloadObject> {
    const getProductDetailsResponse = await this.productsRepo.getProductDetails(payload);
    return getProductDetailsResponse;
  }

  async getListOfProducts(arg?: PaginationPayload): Promise<GetProductsResponsePayload> {
    const getListOfProductsResponse = await this.productsRepo.getListOfProducts(arg);
    return getListOfProductsResponse;
  }

  async getTotalNumberOfProduct(): Promise<TotalNumberOfProductsResponsePayload> {
    const getTotalNumberOfProductResponse = await this.productsRepo.getTotalNumberOfProduct();
    return getTotalNumberOfProductResponse;
  }

  async updateProduct(payload: UpdateProductPayload): Promise<UpdateProductResponsePayload> {
    const updateProductResponse = await this.productsRepo.updateProduct(payload);
    return updateProductResponse;
  }

  async updateProductStockAlert(payload: ProductOutOfStockRequestPayload): Promise<ProductOutOfStockResponsePayload> {
    const response = await this.productsRepo.updateProductStockAlert(payload);
    return response;
  }

  async updateProductStatus(payload: UpdateProductStatusPayload): Promise<GetProductDetailsResponsePayloadObject> {
    const response = await this.productsRepo.updateProductStatus(payload);
    return response;
  }

  async getVendorCommission(): Promise<GetVendorCommissionResponsePayload> {
    const response = await this.productsRepo.getVendorCommission();
    return response;
  }

  async uploadProduct(payload: CreateProductPayload): Promise<UpdateProductResponsePayload> {
    const response = await this.productsRepo.uploadProduct(payload);
    return response;
  }

  async getVendorInventories(payload: GetVendorInventoriesRequestParams): Promise<GetVendorInventoriesResponsePayload> {
    const response = await this.productsRepo.getVendorInventories(payload);
    return response;
  }

  async getVendorInventory(payload: GetVendorInventoryRequestPayload): Promise<GetVendorInventoryResponsePayload> {
    const response = await this.productsRepo.getVendorInventory(payload);
    return response;
  }

  async addInventoryDiscount(
    payload: AddInventoryDiscountRequestPayload
  ): Promise<AddInventoryDiscountResponsePayload> {
    const response = await this.productsRepo.addInventoryDiscount(payload);
    return response;
  }
}
