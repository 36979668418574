import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { DeclineOrderState, DeclineOrderPayload } from '../order_types';
import { OrderRepository } from '../repositories/order.repo';
import { OrderService } from '../services/order.service';

const NAMESPACE = 'DECLINE_ORDER';

const initialState: DeclineOrderState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const declineOrder = createAsyncThunk(`${NAMESPACE}/declineOrder`, async (payload: DeclineOrderPayload) => {
  const orderRepo = new OrderRepository();
  const orderService = new OrderService(orderRepo);
  const response = await orderService.declineOrder(payload);
  return response;
});

export const declineOrderSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetDeclineOrderStatus: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(declineOrder.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(declineOrder.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(declineOrder.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetDeclineOrderStatus } = declineOrderSlice.actions;

export default declineOrderSlice.reducer;
