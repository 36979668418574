import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { CreatePromoState } from '../promotion_types';
import { PromotionRepository } from '../repositories/promotion.repo';
import { PromotionService } from '../services/promotion.service';
import { CreatePromoSchema } from '../../../schema/promo.schema';

const NAMESPACE = 'CREATE_PROMO';

const initialState: CreatePromoState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const createPromo = createAsyncThunk(`${NAMESPACE}/post`, async (payload: CreatePromoSchema) => {
  const promotionRepo = new PromotionRepository();
  const promotionService = new PromotionService(promotionRepo);
  const response = await promotionService.createPromo(payload);
  return response;
});

const createPromoSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createPromo.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(createPromo.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(createPromo.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetStatus } = createPromoSlice.actions;

export default createPromoSlice.reducer;
