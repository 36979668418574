import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetVendorInventoryRequestPayload, GetVendorInventoryState } from '../product_types';
import { ProductsRepository } from '../repositories/products.repo';
import { ProductsService } from '../services/products.service';

const NAMESPACE = 'GET_INVENTORY';

const initialState: GetVendorInventoryState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const getVendorInventory = createAsyncThunk(
  `${NAMESPACE}/get`,
  async (payload: GetVendorInventoryRequestPayload) => {
    const productsRepo = new ProductsRepository();
    const productsService = new ProductsService(productsRepo);
    const response = await productsService.getVendorInventory(payload);
    return response;
  }
);

export const getVendorInventorySlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVendorInventory.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getVendorInventory.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(getVendorInventory.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetStatus } = getVendorInventorySlice.actions;

export default getVendorInventorySlice.reducer;
