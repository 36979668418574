import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { AddInventoryDiscountRequestPayload, ReduxInitialState } from '../product_types';
import { ProductsRepository } from '../repositories/products.repo';
import { ProductsService } from '../services/products.service';

const NAMESPACE = 'ADD_INVENTORY_DISCOUNT';

const initialState: ReduxInitialState = {
  status: HttpStatus.IDLE,
  message: undefined,
};

export const addInventoryDiscount = createAsyncThunk(
  `${NAMESPACE}/patch`,
  async (payload: AddInventoryDiscountRequestPayload) => {
    const productsRepo = new ProductsRepository();
    const productsService = new ProductsService(productsRepo);
    const response = await productsService.addInventoryDiscount(payload);
    return response;
  }
);

const addInventoryDiscountSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(addInventoryDiscount.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(addInventoryDiscount.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(addInventoryDiscount.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetState } = addInventoryDiscountSlice.actions;

export default addInventoryDiscountSlice.reducer;
