import { paginationFormatter } from '../../../commons/pagination_formatter';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { Api } from '../../../utils';
import {
  AddInventoryDiscountRequestPayload,
  AddInventoryDiscountResponsePayload,
  CreateProductPayload,
  CreateProductResponsePayload,
  DeleteProductResponsePayload,
  GetProductDetailsPayload,
  GetProductDetailsResponsePayloadObject,
  GetProductsResponsePayload,
  GetVendorCommissionResponsePayload,
  GetVendorInventoriesRequestParams,
  GetVendorInventoriesResponsePayload,
  GetVendorInventoryRequestPayload,
  GetVendorInventoryResponsePayload,
  ProductOutOfStockRequestPayload,
  ProductOutOfStockResponsePayload,
  TotalNumberOfProductsResponsePayload,
  UpdateProductPayload,
  UpdateProductResponsePayload,
  UpdateProductStatusPayload,
} from '../product_types';

export class ProductsRepository {
  async createProduct(payload: CreateProductPayload): Promise<CreateProductResponsePayload> {
    const response = await Api.post<CreateProductResponsePayload, CreateProductResponsePayload>(
      'v1/product/create-product',
      payload
    );
    return response;
  }

  async deleteProduct(payload: GetProductDetailsPayload): Promise<DeleteProductResponsePayload> {
    const response = await Api.put<DeleteProductResponsePayload, DeleteProductResponsePayload>(
      'v1/product/delete-product',
      payload
    );
    return response;
  }

  async getProductDetails(payload: GetProductDetailsPayload): Promise<GetProductDetailsResponsePayloadObject> {
    const response = await Api.get<GetProductDetailsResponsePayloadObject, GetProductDetailsResponsePayloadObject>(
      'v1/product/product-details',
      { params: payload }
    );
    return response;
  }

  async getListOfProducts(arg?: PaginationPayload): Promise<GetProductsResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<GetProductsResponsePayload, GetProductsResponsePayload>('/v1/product', {
      params,
    });
    return response;
  }

  async getTotalNumberOfProduct(): Promise<TotalNumberOfProductsResponsePayload> {
    const response = await Api.get<TotalNumberOfProductsResponsePayload, TotalNumberOfProductsResponsePayload>(
      'v1/product/total-products'
    );
    return response;
  }

  async updateProduct(payload: UpdateProductPayload): Promise<UpdateProductResponsePayload> {
    const response = await Api.patch<UpdateProductResponsePayload, UpdateProductResponsePayload>(
      '/a/v1/inventory',
      payload
    );
    return response;
  }

  async updateProductStockAlert(payload: ProductOutOfStockRequestPayload): Promise<ProductOutOfStockResponsePayload> {
    const response = await Api.patch<ProductOutOfStockResponsePayload, ProductOutOfStockResponsePayload>(
      '/a/v1/inventory/low-stock',
      payload
    );
    return response;
  }

  async updateProductStatus(payload: UpdateProductStatusPayload): Promise<GetProductDetailsResponsePayloadObject> {
    const response = await Api.patch<GetProductDetailsResponsePayloadObject, GetProductDetailsResponsePayloadObject>(
      '/a/v1/inventory/status',
      payload
    );
    return response;
  }

  async getVendorCommission(): Promise<GetVendorCommissionResponsePayload> {
    const response = await Api.get<GetVendorCommissionResponsePayload, GetVendorCommissionResponsePayload>(
      '/v1/admin-commission/vendor-commission'
    );
    return response;
  }

  async uploadProduct(payload: CreateProductPayload): Promise<UpdateProductResponsePayload> {
    const response = await Api.post<UpdateProductResponsePayload, UpdateProductResponsePayload>(
      '/a/v1/inventory',
      payload
    );
    return response;
  }

  async getVendorInventories({
    vendorId,
    vendorType,
    pageType,
    paginationCursor,
  }: GetVendorInventoriesRequestParams): Promise<GetVendorInventoriesResponsePayload> {
    const response = await Api.get<GetVendorInventoriesResponsePayload, GetVendorInventoriesResponsePayload>(
      '/a/v1/vendor/inventories',
      {
        params: { ...paginationFormatter({ pageType, paginationCursor }), vendorId, vendorType },
      }
    );
    return response;
  }

  async getVendorInventory({
    inventoryId,
    vendorType,
  }: GetVendorInventoryRequestPayload): Promise<GetVendorInventoryResponsePayload> {
    const response = await Api.get<GetVendorInventoryResponsePayload, GetVendorInventoryResponsePayload>(
      '/n/v1/inventory',
      {
        params: { inventoryId, vendorType },
      }
    );
    return response;
  }

  async addInventoryDiscount(
    payload: AddInventoryDiscountRequestPayload
  ): Promise<AddInventoryDiscountResponsePayload> {
    const response = await Api.patch<AddInventoryDiscountResponsePayload, AddInventoryDiscountResponsePayload>(
      '/a/v1/inventory/discount',
      payload
    );
    return response;
  }
}
