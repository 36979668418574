interface ColorscodeI {
  10: string;
  20: string;
  30: string;
  40: string;
  50: string;
  60: string;
  70: string;
  80: string;
  90: string;
  100: string;
}

interface BaseColorI {
  transparent: string;
  white: string;
  black: string;
  info: string;
  success: string;
  warning: string;
  secondary: string;
  error: string;
  primary: string;
  primaryLight: string;
  accent: string;
  dark: string;
  darkBlue: string;
  grayWhite: string;
  gray100: string;
  gray200: string;
  gray300: string;
  gray400: string;
  gray500: string;
  gray600: string;
  adminBlue: string;
  defaultText: string;
  lightGreen: string;
  errorLight: string;
  systemDark100: string;
  pending: string;
  pendingLight: string;
  orderSuccessBg: string;
  orderSuccess: string;
  yellowBg: string;
}

interface SystemColorType extends BaseColorI {
  gray: ColorscodeI;
  red: ColorscodeI;
  green: ColorscodeI;
  amber: ColorscodeI;
}

const colors: SystemColorType = {
  transparent: 'transparent',
  white: '#FFFFFF',
  black: '#000000',
  info: '#03a9f4',
  success: '#21ba45',
  warning: '#FCBC00',
  secondary: '#FA9138',
  error: '#C20222',
  primary: '#E7375B',
  primaryLight: '#EE204A',
  accent: 'FFC42D',
  dark: '#1E1E1E',
  darkBlue: '#272643',
  grayWhite: '#F8F8F8',
  gray100: '#979699',
  gray200: '#E1E3E6',
  gray300: '#757D8A',
  gray400: '#7C7070',
  gray500: '#E3E3E6',
  gray600: '#D9D9D9',
  adminBlue: '#1877F2',
  defaultText: '#7B7C7D',
  systemDark100: '#5F6368',
  lightGreen: '#D4EDDA',
  errorLight: '#F8D7DA',
  pending: '#4A4AFF',
  pendingLight: '#E6E6F2',
  orderSuccessBg: '#B6FAE1',
  orderSuccess: '#0DA06A',
  yellowBg: '#FF9900',
  gray: {
    10: '#FAFAFA',
    20: '#F3F4F6',
    30: '#E5E7EB',
    40: '#D1D5DB',
    50: '#9CA3AF',
    60: '#6B7280',
    70: '#4B5563',
    80: '#374151',
    90: '#1F2937',
    100: '#F9FAFB',
  },

  green: {
    10: '#E9F3E9',
    20: '#D3E8D3',
    30: '#BDDCBC',
    40: '#A7D1A6',
    50: '#91C590',
    60: '#7AB97A',
    70: '#64AE64',
    80: '#4EA24D',
    90: '#389737',
    100: '#228B21',
  },
  amber: {
    10: '#FFF8E6',
    20: '#FEF2CC',
    30: '#FEEBB3',
    40: '#FEE499',
    50: '#FEDE80',
    60: '#FDD766',
    70: '#FDD04D',
    80: '#FDC933',
    90: '#FCC31A',
    100: '#FCBC00',
  },
  red: {
    10: '#F9E6E9',
    20: '#F3CCD3',
    30: '#EDB3BD',
    40: '#E79AA7',
    50: '#E18191',
    60: '#DA677A',
    70: '#D44E64',
    80: '#CE354E',
    90: '#C81B38',
    100: '#C20222',
  },

  // ...
};
export default colors;

//
